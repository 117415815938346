export const getVariantClass = (variant = '') => {
  if (variant === 'xs') return 'xs'
  if (variant === 's') return 's'
  if (variant === 'xl') return 'xl'
  if (variant === 'title') return 'title'
  if (variant === 'subtitle') return 'subtitle'
  if (variant === 'caption') return 'caption'
  if (variant === 'h1') return 'h1'
  if (variant === 'h2') return 'h2'
  if (variant === 'h3') return 'h3'
  if (variant === 'h4') return 'h4'
  if (variant === 'h5') return 'h5'
  if (variant === 'h6') return 'h6'
  return 'body1'
}

export const getFontClass = (font = '') => {
  if (font === 'italic') return 'roobert-italic'
  if (font === 'medium') return 'roobert-medium'
  if (font === 'semibold') return 'roobert-semibold'
  return 'roobert'
}

export const getThemeClass = (theme = '') => {
  if (theme === 'dark') return ' dark'
  if (theme === 'light') return ' light'
  if (theme === 'lighter') return ' lighter'
  if (theme === 'error') return ' error'
  if (theme === 'link') return ' link'
  return ''
}
