import React from 'react'
import { useStoreState } from 'easy-peasy'

import { ReactComponent as CounterMissingImagesSvg } from '../../../svg/counter_missing_images.svg'
import { ReactComponent as CounterSystemErrorsSvg } from '../../../svg/counter_system_errors.svg'
import { ReactComponent as CounterNotificationsSvg } from '../../../svg/counter_notifications.svg'
import { ReactComponent as CounterCommentsSvg } from '../../../svg/counter_comments.svg'
import { ReactComponent as CounterApprovedImagesSvg } from '../../../svg/counter_approved_images.svg'

import './index.scss'

const Stats = () => {
  const orderState = useStoreState((state) => ({
    statsCounts: state.order.statsCounts,
  }))

  return (
    <div className="header--wraps header--counters__wrap">
      <div className="header--counters__count--wrap">
        <div>
          <div className="header--counters__icon">
            <CounterMissingImagesSvg className="top-2" />
          </div>
          <div className="header--counters__value" style={{ color: '#FF9B42' }}>
            {orderState.statsCounts.missing_images}
          </div>
        </div>

        <div>
          <div className="header--counters__icon">
            <CounterSystemErrorsSvg className="top-1" />
          </div>
          <div className="header--counters__value" style={{ color: '#DB5375' }}>
            {orderState.statsCounts.system_errors}
          </div>
        </div>

        <div>
          <div className="header--counters__icon">
            <CounterNotificationsSvg className="top-1" />
          </div>
          <div className="header--counters__value" style={{ color: '#FF9B42' }}>
            {orderState.statsCounts.notifications}
          </div>
        </div>

        <div>
          <div className="header--counters__icon">
            <CounterCommentsSvg className="top-2" />
          </div>
          <div className="header--counters__value" style={{ color: '#FFF' }}>
            {orderState.statsCounts.comments}
          </div>
        </div>

        <div>
          <div className="header--counters__icon">
            <CounterApprovedImagesSvg className="top-1" />
          </div>
          <div className="header--counters__value" style={{ color: '#8ACB88' }}>
            {orderState.statsCounts.confirmed_images}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stats
