import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const Toggle = ({
  id,
  label,
  checked,
  onChange,
}) => (
  <div className="toggle-container">
    <input
      type="checkbox"
      className="toggle"
      id={id}
      checked={checked}
      onChange={onChange}
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label htmlFor={id} className="toggle-icon" />
    <label htmlFor={id}>{label}</label>
  </div>
)

Toggle.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

Toggle.defaultProps = {
  id: '',
  label: '',
  checked: false,
  onChange: () => { },
}

export default Toggle
