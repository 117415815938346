import React from 'react'
import { useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'
import {
  DEFAULT_IMAGE_SIZES,
  IMAGE_TYPES,
  SKELETON_CLASS,
} from '../../../helpers/Constants'

import Preview from './Preview'

import './index.scss'

const Previews = ({ refreshGallery }) => {
  const orderState = useStoreState((state) => ({
    filteredImages: state.order.filteredImages,
    imageType: state.order.imageType,
    isImagesLoading: state.order.isImagesLoading,
  }))

  return (
    // eslint-disable-next-line no-nested-ternary
    orderState.filteredImages ? (
      orderState.filteredImages.length ? (
        <>
          {orderState.filteredImages.map((image, index) => (
            <Preview key={image.id} image={image} index={index} refreshGallery={refreshGallery} />
          ))}
        </>
      ) : (
        <h6 className="h6 empty-result">
          {orderState.imageType === IMAGE_TYPES.input
            ? Translation.sorry_no_images_found : Translation.no_output_images_found}
        </h6>
      )
    ) : (
      orderState.isImagesLoading ? (
        <>
          {[...Array(12)].map((x, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className="gallery-image--wrap"
              style={{ width: `${DEFAULT_IMAGE_SIZES.width}px`, height: '400px' }}
            >
              <div
                className={`image-preview--bg ${SKELETON_CLASS}`}
                style={{ width: `${DEFAULT_IMAGE_SIZES.width}px`, height: `${DEFAULT_IMAGE_SIZES.height}px` }}
              />

              <div className="image-preview--details">
                <div className="image-preview--details__filename">
                  <span className="image-preview--details__filename--filename">
                    <div
                      className={SKELETON_CLASS}
                      style={{
                        width: '100px', height: '10px', marginBottom: '10px', marginTop: '5px',
                      }}
                    />
                  </span>
                </div>

                <div className="image-preview--details__sizes--wrap">
                  <div className="image-preview--details__sizes">
                    <div className={SKELETON_CLASS} style={{ width: '150px', height: '10px', marginBottom: '10px' }} />
                  </div>

                  <div className="image-preview--details__colour-space">
                    <div className={SKELETON_CLASS} style={{ width: '140px', height: '10px', marginBottom: '10px' }} />
                  </div>

                  <div className="image-preview--details__bg-colour">
                    <div className={SKELETON_CLASS} style={{ width: '120px', height: '10px', marginBottom: '10px' }} />
                  </div>

                  <div className="image-preview--details__colour-depth">
                    <div className={SKELETON_CLASS} style={{ width: '110px', height: '10px', marginBottom: '10px' }} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <h6 className="h6 empty-result">
          {Translation.sorry_order_is_not_found}
        </h6>
      )
    )
  )
}

Previews.propTypes = {
  refreshGallery: PropTypes.func,
}

Previews.defaultProps = {
  refreshGallery: () => { },
}

export default Previews
