import { action, thunk } from 'easy-peasy'

import { axiosInstance as axios } from '../helpers/Axios'
import { API_ENDPOINTS } from '../helpers/Urls'
import { Translation } from '../helpers/Translation'
import { isAdminRole } from '../helpers/Utils'

const UserStore = {
  apiErrors: '',
  user: {},

  addApiErrors: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = payload
  }),

  clearApiErrors: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = ''
  }),

  addUser: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.user = { ...payload, is_admin: isAdminRole(payload.role_after_login) }
  }),

  me: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.me)

      if (response.data.result.success) {
        actions.addUser(response.data.result.user_account)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),
}

export default UserStore
