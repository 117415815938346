import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import {
  AVATAR_STYLE_LEFT,
  INTERVAL_60_SECONDS,
} from '../../../helpers/Constants'
import { isAdminRole } from '../../../helpers/Utils'
import { Translation } from '../../../helpers/Translation'

import './index.scss'

const Avatars = () => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    orderId: state.order.orderId,
    imageType: state.order.imageType,
    orderViewingUsers: state.order.orderViewingUsers,
  }))

  const orderActions = useStoreActions((actions) => ({
    ping: actions.order.ping,
  }))

  const [isMouseOver, setIsMouseOver] = useState(false)

  const ping = async () => {
    orderActions.ping({
      role_after_login: userState.user.role_after_login,
      body: {
        order_id: orderState.orderId,
        image_type: orderState.imageType,
      },
    })
  }

  useEffect(() => {
    let interval
    if (orderState.orderId && userState.user.role_after_login) {
      ping().then(() => { })
      interval = setInterval(() => {
        ping().then(() => { })
      }, INTERVAL_60_SECONDS)
    }

    return () => clearInterval(interval)
  }, [orderState.orderId, userState.user.role_after_login])

  const onHandleMouseEnter = () => {
    setIsMouseOver(true)
  }

  const onHandleMouseLeave = () => {
    setIsMouseOver(false)
  }

  return (
    isAdminRole(userState.user.role_after_login) && orderState?.orderViewingUsers?.length ? (
      <div
        className="header--wraps header--avatars__wrap"
        onMouseEnter={onHandleMouseEnter}
        onMouseLeave={onHandleMouseLeave}
      >
        {orderState.orderViewingUsers.length > 1 && (
          <div
            className="header--avatar"
            style={{
              backgroundColor: '#fff',
              color: '#000',
            }}
          >
            +
            {' '}
            {orderState.orderViewingUsers.length - 1}
          </div>
        )}
        <div
          className={`header--avatar ${orderState.orderViewingUsers[0]?.is_client ? 'header--avatar__client' : ''}`}
          style={{
            zIndex: 1,
            left: `${AVATAR_STYLE_LEFT}px`,
            backgroundColor: orderState.orderViewingUsers[0]?.color,
          }}
        >
          {orderState.orderViewingUsers[0]?.nickname}
        </div>
        {isMouseOver ? (
          <div className="header-users">
            <span className="bold">
              {orderState.orderViewingUsers.slice(0, -1).map((user) => (
                // eslint-disable-next-line prefer-template
                user.firstname + ' ' + user.lastname + (user.is_client ? ' (CL)' : '')
              )).join(', ')}
            </span>
            {orderState.orderViewingUsers.length > 1 && (
              <span>
                {' '}
                {Translation.and}
                {' '}
              </span>
            )}
            <span className="bold">
              {orderState.orderViewingUsers.slice(-1).map((user) => (
                `${user.firstname} ${user.lastname} ${(user.is_client ? '(CL)' : '')}`
              ))}
            </span>
            <span>
              {' '}
              {Translation.also_viewing}
            </span>
          </div>
        ) : null}
      </div>
    ) : null
  )
}

export default Avatars
