import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import { dragElement } from '../../../helpers/Utils'
import { PX_TO_REM } from '../../../helpers/Constants'

import Typography from '../../../components/Typography'
import Checkbox from '../../../components/Checkbox'

import { ReactComponent as ChevronDownSvg } from '../../../svg/info_bar_chevron_down.svg'
import { ReactComponent as CrossSvg } from '../../../svg/action_cross.svg'

import './index.scss'

const InfoBar = () => {
  const layoutState = useStoreState((state) => ({
    isInfosOpened: state.layout.isInfosOpened,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateInfosOpened: actions.layout.updateInfosOpened,
  }))

  const [isInitialPos, setIsInitialPos] = useState(true)
  const [isGeneralInfoCollapsed, setIsGeneralInfoCollapsed] = useState(false)
  const [isChecklistCollapsed, setIsChecklistCollapsed] = useState(false)

  const infoBarRef = useRef(null)
  const dragEventRef = useRef(null)

  const handleCloseClick = () => {
    layoutActions.updateInfosOpened(false)
  }

  const handleMouseUp = () => {
    if (
      (infoBarRef.current.style.top === '0px'
        && infoBarRef.current.style.left === '0px')
      || (infoBarRef.current.style.top === ''
        && infoBarRef.current.style.left === '')
    ) {
      setIsInitialPos(true)
    } else {
      setIsInitialPos(false)
    }
  }

  useEffect(() => {
    if (layoutState.isInfosOpened) {
      dragEventRef.current = dragElement(infoBarRef.current)
      infoBarRef.current.addEventListener('mousedown', dragEventRef.current)
      infoBarRef.current.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      infoBarRef.current?.removeEventListener('mousedown', dragEventRef.current)
      infoBarRef.current?.removeEventListener('mouseup', handleMouseUp)
    }
  }, [layoutState.isInfosOpened])

  const handleCollapseClick = (section) => {
    if (section === 'general') {
      setIsGeneralInfoCollapsed(!isGeneralInfoCollapsed)
    } else {
      setIsChecklistCollapsed(!isChecklistCollapsed)
    }
  }

  return (
    <div
      className="info-bar"
      ref={infoBarRef}
      style={{
        bottom: isInitialPos ? '0' : 'auto',
      }}
    >
      <button
        className="info-bar_close"
        type="button"
        label="Close"
        onClick={handleCloseClick}
      >
        <CrossSvg />
      </button>

      <div className="order-status">
        <Typography
          variant="body1"
          font="semibold"
          label={`${Translation.order_status}: Redo`}
          lineHeight={PX_TO_REM['24']}
        />
      </div>

      <div className="section">
        <button
          className="collapse-btn"
          type="button"
          label="collapse"
          onClick={() => handleCollapseClick('general')}
        >
          <ChevronDownSvg />
        </button>

        <div className="section-header">
          <Typography
            variant="body1"
            font="semibold"
            label={Translation.general_order_info}
            lineHeight={PX_TO_REM['24']}
          />
        </div>
        {!isGeneralInfoCollapsed && (
          <>
            <div>
              <ul>
                <li>
                  <Typography
                    label="Format: PSD"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="B/G - Variable"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="3450 x 3900px"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="300 dpi"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="sRGB"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="100px margin (b)"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
              </ul>
            </div>

            <div className="section-header">
              <Typography
                variant="body1"
                font="semibold"
                label={`${Translation.retouch}: General`}
                lineHeight={PX_TO_REM['24']}
              />
            </div>

            <div>
              <ul>
                <li>
                  <Typography
                    label="Dirt"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="Sctratches"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="Dust"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="reflections"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="Wrinkles"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="Other"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
              </ul>
            </div>

            <div className="section-header">
              <Typography
                variant="body1"
                font="semibold"
                label={`${Translation.retouch}: General`}
                lineHeight={PX_TO_REM['24']}
              />
            </div>

            <div>
              <ul>
                <li>
                  <Typography
                    label="Scars"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="Pimples"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="Moles"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="Tatoos"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="Beauty"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
                <li>
                  <Typography
                    label="Other"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                </li>
              </ul>
            </div>
          </>
        )}
      </div>

      <div className="separator" />

      <div className="section">
        <button
          className="collapse-btn"
          type="button"
          label="collapse"
          onClick={() => handleCollapseClick('checklist')}
        >
          <ChevronDownSvg />
        </button>

        <div className="section-header">
          <Typography
            variant="body1"
            font="semibold"
            label={Translation.checklist}
            lineHeight={PX_TO_REM['24']}
          />
        </div>

        {!isChecklistCollapsed && (
          <div>
            <ul>
              <li>
                <div className="check-item">
                  <Typography
                    label="Scars"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
              <li>
                <div className="check-item">
                  <Typography
                    label="Pimples"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
              <li>
                <div className="check-item">
                  <Typography
                    label="Moles"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
              <li>
                <div className="check-item">
                  <Typography
                    label="Tatoos"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
              <li>
                <div className="check-item">
                  <Typography
                    label="Beauty"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
              <li>
                <div className="check-item">
                  <Typography
                    label="Other"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default InfoBar
