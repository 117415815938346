import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const Select = ({ options, value, onChange }) => (
  <div className="doo-select">
    <select
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  </div>
)

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

Select.defaultProps = {
  value: '',
  onChange: () => { },
}

export default Select
