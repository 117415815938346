import React from 'react'
import { useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'

import './index.scss'

const OrderId = () => {
  const orderState = useStoreState((state) => ({
    orderId: state.order.orderId,
  }))

  return (
    <div className="header--wraps header--order-id__wrap">
      <div className="header--order-id__wrap--header">{Translation.order_id}</div>
      <div className="header--order-id__wrap--value">{orderState.orderId}</div>
    </div>
  )
}

export default OrderId
