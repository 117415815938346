import React from 'react'
import PropTypes from 'prop-types'

import { COMMENT_STATUSES, COMMENT_TYPES } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'
import { prepareComment } from '../../../../helpers/Utils'

import { ReactComponent as CommentEditIconSvg } from '../../../../svg/comment_edit_icon.svg'
import { ReactComponent as CommentStatusIconSvg } from '../../../../svg/comment_status_icon.svg'

import './index.scss'

const CommentOverlay = ({ comment }) => (
  <div className="overlay--comment__wrap">
    <div className="overlay--comment__icon overlay--comment__icon--status">
      <CommentEditIconSvg />
    </div>

    <div className="overlay--comment__icon overlay--comment__type">
      <CommentStatusIconSvg />
    </div>

    {(comment.type === COMMENT_TYPES.qaClient) && (
      <div className={`overlay--comment__icon overlay--comment__icon--client 
        ${parseInt(comment.status.toString(), 10) === COMMENT_STATUSES.active
        ? 'overlay--comment__icon--client__approved' : ''}`}
      >
        {Translation.client_short}
      </div>
    )}

    <div className="overlay--comment">
      {prepareComment(comment.comment)}
    </div>
  </div>
)

CommentOverlay.propTypes = {
  comment: PropTypes.instanceOf(Object),
}

CommentOverlay.defaultProps = {
  comment: {},
}

export default CommentOverlay
