import { createStore } from 'easy-peasy'

import global from './GlobalStore'
import layout from './LayoutStore'
import user from './UserStore'
import order from './OrderStore'

export const Store = createStore({
  global,
  layout,
  user,
  order,
})
