import { action, thunk } from 'easy-peasy'

import { axiosInstance as axios } from '../helpers/Axios'
import { API_ENDPOINTS_GALLERY } from '../helpers/Urls'
import {
  ALL_LAYERS_KEY,
  IMAGE_SIZES,
  IMAGE_SORTINGS,
  IMAGE_TYPES,
  PATH_WIDTH_MIN,
} from '../helpers/Constants'
import { Translation } from '../helpers/Translation'
import { getEndpointUrl } from '../helpers/Utils'

const OrderStore = {
  apiErrors: '',
  orderId: 0,

  gallery: null,

  isImagesLoading: true,

  images: null,
  imageType: IMAGE_TYPES.input,
  imageSize: IMAGE_SIZES.small,
  imageSort: IMAGE_SORTINGS.id,

  imageSizeSlide: 1,

  links: {},

  statsCounts: {
    missing_images: 0,
    system_errors: 0,
    notifications: 0,
    comments: 0,
    confirmed_images: 0,
  },

  filteredImages: null,
  filterBy: 'file',
  filterWord: '',

  imageDetails: {},
  overlayImgIndex: 0,

  updatedImages: [],
  selectedImages: [],

  imagesToBeDeleted: [],
  commentsToBeDeleted: [],
  imageToBeAllowedOverwrite: null,

  downloadUrls: {},

  explodeOrderList: null,

  layerUrls: {},
  paths: {},
  pathRange: parseInt(localStorage.getItem('pathRange'), 10) || PATH_WIDTH_MIN,
  selectedBgColor: null,
  selectedImagePaths: {},
  selectedLayer: ALL_LAYERS_KEY,
  selectedGrids: [],
  orderViewingUsers: [],

  addApiErrors: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = payload
  }),

  clearApiErrors: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = ''
  }),

  setGallery: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.gallery = payload
  }),

  setOrderId: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderId = payload
  }),

  setImageType: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageType = payload
  }),

  setImageSize: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageSize = payload
  }),

  setImageSizeSlide: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageSizeSlide = payload
  }),

  setImageSort: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageSort = payload
  }),

  setDownloadUrls: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.downloadUrls = payload
  }),

  setIsImagesLoading: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isImagesLoading = payload
  }),

  setImages: action((state, payload) => {
    const oldImages = JSON.stringify(state.images)
    if (state.images !== null && oldImages !== JSON.stringify(payload)) {
      const images = JSON.parse(oldImages)
      // eslint-disable-next-line no-param-reassign
      state.updatedImages = payload?.filter(
        (image) => image.image_property_timestamp !== images.find(
          (i) => i.id === image.id,
        )?.image_property_timestamp,
      )
    }
    // eslint-disable-next-line no-param-reassign
    state.isImagesLoading = false
    // eslint-disable-next-line no-param-reassign
    state.images = payload
  }),

  resetUpdatedImages: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.updatedImages = []
  }),

  setLinks: action((state, payload) => {
    const newPayload = {}
    Object.keys(payload.urls).forEach((key) => {
      newPayload[key] = {
        ...state.links[key],
        [payload.imageSize]: payload.urls[key],
      }
    })
    // eslint-disable-next-line no-param-reassign
    state.links = { ...state.links, ...newPayload }
  }),

  resetLinks: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.links = {}
  }),

  setFilteredImages: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filteredImages = payload
  }),

  setFilterBy: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filterBy = payload
  }),

  setFilterWord: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filterWord = payload
  }),

  setImageDetails: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageDetails = { ...state.imageDetails, ...payload }
  }),

  resetImageDetails: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.imageDetails = {}
  }),

  setOverlayImgIndex: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.overlayImgIndex = payload
  }),

  setSelectedImages: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedImages = payload
  }),

  filterSelectedImages: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedImages = state.selectedImages.filter((imageId) => payload.find((image) => image.id === imageId))
  }),

  setImagesToBeDeleted: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imagesToBeDeleted = payload
  }),

  setCommentsToBeDeleted: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.commentsToBeDeleted = payload
  }),

  setExplodeOrderList: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.explodeOrderList = payload
  }),

  setImageToBeAllowedOverwrite: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageToBeAllowedOverwrite = payload
  }),

  setLayerUrls: action((state, { res, imageId }) => {
    const imageLayers = state.layerUrls[imageId]
    if (imageLayers) {
      // eslint-disable-next-line no-param-reassign
      state.layerUrls[imageId] = { ...imageLayers, ...res[imageId] }
    } else {
      // eslint-disable-next-line no-param-reassign
      state.layerUrls = {
        ...state.layerUrls,
        ...res,
      }
    }
  }),

  setPaths: action((state, payload) => {
    Object.keys(payload).forEach((imageId) => {
      const imagePaths = state.paths[imageId]
      if (imagePaths) {
        // eslint-disable-next-line no-param-reassign
        state.paths[imageId] = { ...imagePaths, ...payload[imageId] }
      } else {
        // eslint-disable-next-line no-param-reassign
        state.paths = {
          ...state.paths,
          ...payload,
        }
      }
    })
  }),

  setPathRange: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.pathRange = payload
    localStorage.setItem('pathRange', payload)
  }),

  setSelectedImagePaths: action((state, payload) => {
    Object.keys(payload).forEach((imageId) => {
      const imagePaths = state.selectedImagePaths[imageId]
      if (imagePaths) {
        // eslint-disable-next-line no-param-reassign
        state.selectedImagePaths[imageId] = { ...imagePaths, ...payload[imageId] }
      } else {
        // eslint-disable-next-line no-param-reassign
        state.selectedImagePaths = {
          ...state.selectedImagePaths,
          ...payload,
        }
      }
    })
  }),

  setSelectedBgColor: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedBgColor = payload
  }),

  setSelectedLayer: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedLayer = payload
  }),

  setSelectedGrids: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedGrids = payload
  }),

  setOrderViewingUsers: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderViewingUsers = payload
  }),

  getGallery: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.gallery)

      const response = await axios.get(url.replace('{id}', payload.orderId), {
        params: {
          image_type: payload.imageType,
        },
      })

      if (response.data.result.success) {
        actions.setGallery(response.data.result)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getImages: thunk(async (actions, payload) => {
    try {
      actions.setIsImagesLoading(true)
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.galleryImages)

      const response = await axios.get(url.replace('{id}', payload.orderId), {
        params: {
          image_type: payload.imageType,
          image_size: payload.imageSize,
          image_sort: payload.imageSort,
          image_filter: payload.imageFilter,
          name_search: payload.nameSearch,
        },
      })

      if (response.data.result.success) {
        actions.filterSelectedImages(response.data.result.images)
        actions.setImages(response.data.result.images)
        return true
      }

      actions.setIsImagesLoading(false)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      actions.setIsImagesLoading(false)

      return false
    }
  }),

  getLinks: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS_GALLERY.links, payload)

      if (response.data.result.success) {
        actions.setLinks({ urls: response.data.result.urls, imageSize: payload.image_sizes[0] })
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getImageDetails: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS_GALLERY.image, payload)

      if (response.data.result.success) {
        actions.setImageDetails(response.data.result.images)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  imageAction: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(payload.url, payload.body)

      if (response.data.result.success) {
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  removeError: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.errors)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  addComment: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.commentNew)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  changeReference: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.reference)
      const response = await axios.post(url, payload.body)

      if (response.data.result.success) {
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getExplodeOrderList: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.explode)
      const response = await axios.get(`${url}/${payload.orderId}`)

      if (response.data.result.success) {
        const explodeOrderList = {}
        response.data.result.explode_order_list.forEach((item) => {
          explodeOrderList[item.to_order_id] = item.label
        })
        actions.setExplodeOrderList(explodeOrderList)
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  explodeOrder: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.explode)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  updatePrices: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.prices)

      let response
      if (payload.method === 'delete') response = await axios.delete(url, { data: payload.body })
      else response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  setTestImage: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.test)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  restoreImages: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.restore)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  clearImages: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.clear)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getLayer: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS_GALLERY.layer, payload.body)

      if (response.data.result.success) {
        actions.setLayerUrls({ res: response.data.result.urls, imageId: payload.body.image_id })
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getPath: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS_GALLERY.path, payload)

      if (response.data.result.success) {
        actions.setPaths(response.data.result.svgs)
        return response.data.result
      }

      return false
    } catch (error) {
      return false
    }
  }),

  ping: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.role_after_login, API_ENDPOINTS_GALLERY.ping)

      const response = await axios.post(url, payload.body)

      if (response.data.result.success) {
        actions.setOrderViewingUsers(response.data.result.order_viewing_users)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),
}

export default OrderStore
