import React from 'react'
import PropTypes from 'prop-types'

const Download = ({ urls }) => (
  <div style={{ display: 'none' }}>
    {Object.keys(urls).map((key) => (
      <iframe key={key} src={urls[key]} title={key} style={{ display: 'none' }} />
    ))}
  </div>
)

Download.propTypes = {
  urls: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
}

Download.defaultProps = {
  urls: {},
}

export default Download
