import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { IMAGE_SIZES, IMAGE_SORTINGS, INTERVAL_60_SECONDS } from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'
import { debounce, isAuthenticated } from '../../helpers/Utils'
import { MY_DOOPIC_URLS } from '../../helpers/Urls'

import Header from '../Header'
import DotAnimation from '../Common/DotAnimation'

import './index.scss'

const Layout = ({ title, children }) => {
  const params = useParams()

  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const userActions = useStoreActions((actions) => ({
    me: actions.user.me,
  }))

  const globalState = useStoreState((state) => ({
    requestCount: state.global.requestCount,
  }))

  const globalActions = useStoreActions((actions) => ({
    fetchCurrentReleaseVersion: actions.global.fetchCurrentReleaseVersion,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateBreakpoints: actions.layout.updateBreakpoints,
  }))

  const orderState = useStoreState((state) => ({
    orderId: state.order.orderId,
  }))

  const orderActions = useStoreActions((actions) => ({
    setOrderId: actions.order.setOrderId,
    setImageType: actions.order.setImageType,
    setImageSize: actions.order.setImageSize,
    setImageSort: actions.order.setImageSort,
  }))

  const checkCurrentReleaseVersion = async () => {
    const result = await globalActions.fetchCurrentReleaseVersion()
    if (result.react_app_version) {
      // check if the current version is different from the latest version, if not refresh the page
      // check if we have the version info in the local storage first
      const currentVersion = localStorage.getItem('currentReleaseVersion')
      if (!currentVersion) {
        localStorage.setItem('currentReleaseVersion', result.react_app_version)
      } else if (currentVersion !== result.react_app_version) {
        localStorage.setItem('currentReleaseVersion', result.react_app_version)
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    orderActions.setOrderId(parseInt(params.order_id.toString(), 10))
    orderActions.setImageType(params.image_type)
    orderActions.setImageSize(params.image_size ? parseInt(params.image_size.toString(), 10) : IMAGE_SIZES.small)
    orderActions.setImageSort(params.image_sort ? params.image_sort : IMAGE_SORTINGS.id)
  }, [params.order_id, params.image_type, params.image_size, params.image_sort])

  const updateBreakpointsOnResize = debounce(() => layoutActions.updateBreakpoints())

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isAuthenticated()) {
      window.location.href = `${MY_DOOPIC_URLS.login}`
    } else {
      // check if user is not empty
      if (Object.keys(userState.user).length === 0) {
        userActions.me()
      }

      window.addEventListener('resize', updateBreakpointsOnResize)
    }

    checkCurrentReleaseVersion().then(() => { })
    const checkCurrentReleaseVersionInterval = setInterval(() => {
      checkCurrentReleaseVersion().then(() => { })
    }, INTERVAL_60_SECONDS)

    // cleanup: "remove event listeners" and "intervals" to avoid memory leaks by creating the same listeners
    return () => {
      clearInterval(checkCurrentReleaseVersionInterval)
      window.removeEventListener('resize', updateBreakpointsOnResize)
    }
  }, [])

  useEffect(() => {
    document.title = `${orderState.orderId} / ${Translation.order} / ${title} / ${Translation.my_doopic}`
  }, [orderState.orderId])

  const handleLayoutClick = () => {
    // todo Yigit - updateMenuState to show hide drop down menu
  }

  // show empty page, before redirect
  if (!isAuthenticated()) return null

  return (
    <div onClick={handleLayoutClick}>
      <Header />

      {children}

      {globalState.requestCount > 0 && <div className="screen-center general-loading-icon"><DotAnimation /></div>}
    </div>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  title: '',
}

export default Layout
