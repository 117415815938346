import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const Range = ({
  value,
  min,
  max,
  onChange,
  showLabels,
  leftIcon,
  rightIcon,
}) => (
  <div className="slider-container">
    {showLabels && <span>{min}</span>}
    {leftIcon}
    <input
      type="range"
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      className="slider"
    />
    {rightIcon}
    {showLabels && <span>{max}</span>}
  </div>
)

Range.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  showLabels: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
}

Range.defaultProps = {
  value: 1,
  min: 1,
  max: 100,
  onChange: () => { },
  showLabels: false,
  leftIcon: null,
  rightIcon: null,
}

export default Range
