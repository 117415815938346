import React from 'react'

import { Cookie } from './Cookie'

import deTrans from '../locales/de.json'
import enTrans from '../locales/en.json'
import itTrans from '../locales/it.json'

import { ReactComponent as FlagEnSvg } from '../svg/flag_en.svg'
import { ReactComponent as FlagDeSvg } from '../svg/flag_de.svg'
import { ReactComponent as FlagItSvg } from '../svg/flag_it.svg'

export const langEn = 'en'
export const langDe = 'de'
export const langIt = 'it'

export const languages = {
  [langEn]: {
    label: 'English',
    label_en: 'English',
    label_de: 'German',
    label_it: 'Italian',
    icon: <FlagEnSvg />,
  },
  [langDe]: {
    label: 'Deutsch',
    label_en: 'Englisch',
    label_de: 'Deutsch',
    label_it: 'Italienisch',
    icon: <FlagDeSvg />,
  },
  [langIt]: {
    label: 'Italiano',
    label_en: 'Inglese',
    label_de: 'Tedesco',
    label_it: 'Italiano',
    icon: <FlagItSvg />,
  },
}

const getTrans = (locale = langDe) => {
  if (locale === langDe) return deTrans
  if (locale === langIt) return itTrans
  return enTrans
}

const defaultLang = langEn
const cookieLang = '_lang'

const getLang = () => {
  if (Cookie.getCookie(cookieLang)) {
    return Cookie.getCookie(cookieLang)
  }

  const browserLang = window.navigator.language.substring(0, 2)
  if (!browserLang) {
    Cookie.setCookie(cookieLang, defaultLang)
    return defaultLang
  }

  Cookie.setCookie(cookieLang, browserLang)
  return browserLang
}

export const setLang = (lang) => {
  if (lang === langEn || lang === langDe || lang === langIt) {
    Cookie.setCookie(cookieLang, lang)
  }
}

export const lang = getLang()

export const Translation = getTrans(lang)
