import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'

import {
  IMAGE_SIZES,
  INTERVAL_60_SECONDS,
  DEFAULT_THUMBNAIL_TYPE,
  CLIENT_GALLERY_PREFIX,
  ADMIN_GALLERY_PREFIX,
  SELECTABLE_CLASS,
  DRAG_SELECT_CLASS,
} from '../../helpers/Constants'
import {
  debounce,
  getGalleryUrl,
  isInViewport,
} from '../../helpers/Utils'

import Actions from './Actions'
import Dialogs from './Dialogs'
import Download from './Download'
import InfoBar from './InfoBar'
import Overlay from './Overlay'
import Previews from './Previews'
import Loader from '../../layouts/Common/Loader'
import DeleteErrorsDialog from './Dialogs/DeleteErrorsDialog'
import ExplodeOrderDialog from './Dialogs/ExplodeOrderDialog'
import UpdatePricesDialog from './Dialogs/UpdatePricesDialog'

import './index.scss'

const Gallery = () => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isOverlayOpened: state.layout.isOverlayOpened,
    isInfosOpened: state.layout.isInfosOpened,
    isDialogModalOpened: state.layout.isDialogModalOpened,
    isDeleteErrorsModalOpened: state.layout.isDeleteErrorsModalOpened,
    isExplodeOrderModalOpened: state.layout.isExplodeOrderModalOpened,
    isUpdatePricesModalOpened: state.layout.isUpdatePricesModalOpened,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateDialogModalOpened: actions.layout.updateDialogModalOpened,
    updateExplodeOrderModalOpened: actions.layout.updateExplodeOrderModalOpened,
    updateUpdatePricesModalOpened: actions.layout.updateUpdatePricesModalOpened,
    updateDeleteErrorsModalOpened: actions.layout.updateDeleteErrorsModalOpened,
  }))

  const orderState = useStoreState((state) => ({
    orderId: state.order.orderId,
    imageType: state.order.imageType,
    imageSize: state.order.imageSize,
    imageSort: state.order.imageSort,
    images: state.order.images,
    filteredImages: state.order.filteredImages,
    links: state.order.links,
    updatedImages: state.order.updatedImages,
    selectedImages: state.order.selectedImages,
    downloadUrls: state.order.downloadUrls,
  }))

  const orderActions = useStoreActions((actions) => ({
    getImages: actions.order.getImages,
    getGallery: actions.order.getGallery,
    getLinks: actions.order.getLinks,
    resetLinks: actions.order.resetLinks,
    resetUpdatedImages: actions.order.resetUpdatedImages,
    setSelectedImages: actions.order.setSelectedImages,
    resetImageDetails: actions.order.resetImageDetails,
    setImagesToBeDeleted: actions.order.setImagesToBeDeleted,
    setCommentsToBeDeleted: actions.order.setCommentsToBeDeleted,
  }))

  const linksRef = useRef(orderState.links)
  const imagesRef = useRef(orderState.images)
  const galleryContentRef = useRef()

  const location = useLocation()
  const navigate = useNavigate()

  const [selectables, setSelectables] = useState([])
  const selectablesRef = useRef(selectables)
  const selectedImagesRef = useRef(orderState.selectedImages)
  const isOverlayOpenedRef = useRef(layoutState.isOverlayOpened)
  const isDialogModalOpenedRef = useRef(layoutState.isDialogModalOpened)
  const isExplodeOrderModalOpenedRef = useRef(layoutState.isExplodeOrderModalOpened)
  const isDeleteErrorsModalOpenedRef = useRef(layoutState.isDeleteErrorsModalOpened)
  const isUpdatePricesModalOpenedRef = useRef(layoutState.isUpdatePricesModalOpened)
  const refreshGalleryInterval = useRef()

  const handleLayoutScroll = (e) => {
    if (e.currentTarget.scrollTop) {
      // ok, do nothing
    } else {
      // ok, do nothing
    }
  }

  const refreshGallery = () => {
    if (!userState.user.role_after_login
      || !orderState.orderId
      || !orderState.imageType
      || !orderState.imageSize
      || !orderState.imageSort) return

    orderActions.getGallery({
      role_after_login: userState.user.role_after_login,
      ...orderState,
    })

    orderActions.getImages({
      role_after_login: userState.user.role_after_login,
      ...orderState,
      imageFilter: -1,
      nameSearch: '',
    })
  }

  const resetInterval = () => {
    clearInterval(refreshGalleryInterval.current)
    refreshGalleryInterval.current = setInterval(refreshGallery, INTERVAL_60_SECONDS)
  }

  const refreshLinks = () => {
    let linksToGet = imagesRef.current
      ?.filter((image) => !linksRef.current[image.id])
      ?.filter((image) => isInViewport(
        document.getElementById(`image-${image.id}--wrap`),
      )) || []

    linksToGet = linksToGet.concat(orderState.updatedImages)
      ?.filter((image) => image?.id)

    if (linksToGet.length) {
      orderActions.getLinks({
        image_ids: [
          ...linksToGet.map((image) => image?.id),
        ],
        s3_paths: [
          ...linksToGet.map((image) => image?.image_resource[image.id].s3_path),
        ],
        original_s3_paths: [
          ...linksToGet.map((image) => image?.image_resource[image.id].original_s3_path),
        ],
        formats: [
          ...linksToGet.map((image) => image?.image_resource[image.id].format),
        ],
        image_sizes: [
          ...Array(linksToGet.length).fill(orderState.imageSize || IMAGE_SIZES.small),
        ],
        thumbnail_types: [
          ...Array(linksToGet.length).fill(DEFAULT_THUMBNAIL_TYPE),
        ],
      })
      orderActions.resetUpdatedImages()
    }
  }

  const refreshLinksOnResize = debounce(() => refreshLinks())
  const refreshLinksOnScrollend = () => refreshLinks()

  useEffect(() => {
    isOverlayOpenedRef.current = layoutState.isOverlayOpened
  }, [layoutState.isOverlayOpened])

  const closeModalOnEsc = (e) => {
    if (e.key === 'Escape') {
      layoutActions.updateDialogModalOpened(false)
      layoutActions.updateExplodeOrderModalOpened(false)
      layoutActions.updateDeleteErrorsModalOpened(false)
      layoutActions.updateUpdatePricesModalOpened(false)
    }
  }

  useEffect(() => {
    isDialogModalOpenedRef.current = layoutState.isDialogModalOpened
    if (!layoutState.isDialogModalOpened) {
      orderActions.setImagesToBeDeleted([])
      orderActions.setCommentsToBeDeleted([])
      document.removeEventListener('keydown', closeModalOnEsc)
    } else {
      document.addEventListener('keydown', closeModalOnEsc)
    }
  }, [layoutState.isDialogModalOpened])

  useEffect(() => {
    selectablesRef.current = selectables
  }, [selectables])

  useEffect(() => {
    selectedImagesRef.current = orderState.selectedImages
  }, [orderState.selectedImages])

  useEffect(() => {
    isExplodeOrderModalOpenedRef.current = layoutState.isExplodeOrderModalOpened
    isDeleteErrorsModalOpenedRef.current = layoutState.isDeleteErrorsModalOpened
    isUpdatePricesModalOpenedRef.current = layoutState.isUpdatePricesModalOpened
    if (
      layoutState.isExplodeOrderModalOpened
      || layoutState.isUpdatePricesModalOpened
      || layoutState.isDeleteErrorsModalOpened
    ) {
      document.addEventListener('keydown', closeModalOnEsc)
    } else {
      document.removeEventListener('keydown', closeModalOnEsc)
    }
  }, [
    layoutState.isExplodeOrderModalOpened,
    layoutState.isUpdatePricesModalOpened,
    layoutState.isDeleteErrorsModalOpened,
  ])

  const setDataset = () => {
    const list = []
    const selectableElems = [...document.querySelectorAll(`.${SELECTABLE_CLASS}`)]
    selectableElems.forEach((selectable) => {
      const {
        x,
        y,
        width,
        height,
      } = selectable.getBoundingClientRect()
      list.push({
        x: x + window.scrollX,
        y: y + window.scrollY,
        width,
        height,
        elem: selectable,
      })
      // eslint-disable-next-line no-param-reassign
      selectable.dataset.info = JSON.stringify({
        x,
        y,
        width,
        height,
      })
    })
    setSelectables(list)
  }

  const checkRectIntersection = (r1, r2) => !(r1.x + r1.width < r2.x
    || r2.x + r2.width < r1.x
    || r1.y + r1.height < r2.y
    || r2.y + r2.height < r1.y)

  const checkSelected = (selectAreaElem) => {
    const select = selectAreaElem.getBoundingClientRect()
    const {
      x,
      y,
      height,
      width,
    } = select
    const selectedList = []
    selectablesRef.current.forEach((selectable) => {
      if (
        checkRectIntersection({
          x: x + window.scrollX,
          y: y + window.scrollY,
          height,
          width,
        }, selectable)
      ) {
        selectedList.push(parseInt(selectable.elem.dataset.imageId, 10))
      }
    })
    return selectedList
  }

  const createSelectAreaDiv = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    const x = event.pageX
    const y = event.pageY

    const div = document.createElement('div')
    div.style.position = 'absolute'
    div.style.width = '0'
    div.style.height = '0'
    div.style.left = `${x}px`
    div.style.top = `${y - 60}px`
    div.classList.add(DRAG_SELECT_CLASS)

    if (document.querySelector(`.${DRAG_SELECT_CLASS}`)) {
      document.querySelector(`.${DRAG_SELECT_CLASS}`).remove()
    }

    galleryContentRef.current.append(div)

    let selectedList = []

    const resize = (e) => {
      if (document.querySelector(`.${DRAG_SELECT_CLASS}`)) {
        const diffX = e.pageX - x
        const diffY = e.pageY - y
        div.style.left = diffX < 0 ? `${x + diffX}px` : `${x}px`
        const top = y
          + (diffY < 0 ? diffY : 0)
          - 60
          + galleryContentRef.current.scrollTop
        div.style.top = `${top}px`
        div.style.height = `${Math.abs(diffY + (galleryContentRef.current.scrollTop > 0
          ? 0
          : galleryContentRef.current.scrollTop))}px`
        div.style.width = `${Math.abs(diffX)}px`
        selectedList = checkSelected(div)
      }
    }

    galleryContentRef.current.addEventListener('mousemove', resize)
    galleryContentRef.current.addEventListener('mouseup', () => {
      // eslint-disable-next-line no-restricted-globals
      galleryContentRef.current?.removeEventListener('mousemove', resize)
      div.remove()

      // if no selected images, then do nothing
      if (!selectedList.length) return

      // if selectedList contains items from selectedImages, then remove them
      const newSelectedImages = selectedImagesRef.current.filter((selectedImage) => !selectedList.includes(selectedImage))
      // if selectedList contains items not in selectedImages, then add them
      selectedList.forEach((selectedImage) => {
        if (!selectedImagesRef.current.includes(selectedImage)) {
          newSelectedImages.push(selectedImage)
        }
      })
      orderActions.setSelectedImages(newSelectedImages)
    }, { once: true })
  }

  useEffect(() => {
    if (!userState.user.role_after_login
      || !orderState.orderId
      || !orderState.imageType
      || !orderState.imageSize
      || !orderState.imageSort) return () => { }

    refreshGallery()

    galleryContentRef.current.scrollTo({ behavior: 'smooth', top: 0 })

    // "add event listeners" and "intervals"
    window.addEventListener('resize', refreshLinksOnResize)
    galleryContentRef.current.addEventListener('scrollend', refreshLinksOnScrollend)
    refreshGalleryInterval.current = setInterval(refreshGallery, INTERVAL_60_SECONDS)

    // cleanup: "remove event listeners" and "intervals" to avoid memory leaks by creating the same listeners
    return () => {
      window.removeEventListener('resize', refreshLinksOnResize)
      galleryContentRef.current?.removeEventListener('scrollend', refreshLinksOnScrollend)
      clearInterval(refreshGalleryInterval.current)
    }
  }, [orderState.orderId, orderState.imageType, orderState.imageSize, orderState.imageSort, userState.user.role_after_login])

  useEffect(() => {
    if (orderState.updatedImages?.length) {
      refreshLinks()
      orderActions.resetImageDetails()
    }
  }, [orderState.updatedImages])

  useEffect(() => {
    if (linksRef.current !== orderState.links) {
      linksRef.current = orderState.links
    }
  }, [orderState.links])

  useEffect(() => {
    if (!orderState.images) return

    if (imagesRef.current !== orderState.images) {
      imagesRef.current = orderState.images
    }

    // looks like not needed#: because it creates duplicated requests on first page load
    // setTimeout(() => refreshLinks(), 100)
  }, [orderState.images])

  const handleKeyDown = (e) => {
    if (e.key === 'Shift') {
      galleryContentRef.current.addEventListener('mousedown', createSelectAreaDiv)
    }
    if (
      e.code === 'KeyA'
      && e.shiftKey
      && orderState.filteredImages.length !== selectedImagesRef.current.length
      && !isOverlayOpenedRef.current
      && !isDialogModalOpenedRef.current
      && !isExplodeOrderModalOpenedRef.current
      && !isDeleteErrorsModalOpenedRef.current
      && !isUpdatePricesModalOpenedRef.current
    ) {
      orderActions.setSelectedImages(orderState.filteredImages.map((image) => image.id))
    } else if (
      e.code === 'KeyA'
      && e.shiftKey
      && orderState.filteredImages.length === selectedImagesRef.current.length
      && !isOverlayOpenedRef.current
      && !isDialogModalOpenedRef.current
      && !isExplodeOrderModalOpenedRef.current
      && !isDeleteErrorsModalOpenedRef.current
      && !isUpdatePricesModalOpenedRef.current
    ) {
      orderActions.setSelectedImages([])
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Shift') {
      galleryContentRef.current?.removeEventListener('mousedown', createSelectAreaDiv)
    }
  }

  useEffect(() => {
    if (!orderState.filteredImages) return () => { }

    setDataset()

    galleryContentRef.current.addEventListener('scrollend', setDataset)

    // check if shift key is pressed
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    refreshLinks()

    // cleanup: "remove event listeners" and "intervals" to avoid memory leaks by creating the same listeners
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
      galleryContentRef.current?.removeEventListener('scrollend', setDataset)
    }
  }, [orderState.filteredImages])

  useEffect(() => {
    orderActions.resetLinks()
  }, [orderState.orderId, orderState.imageType, orderState.imageSize])

  useEffect(() => {
    orderActions.setSelectedImages([])
  }, [orderState.imageType])

  useEffect(() => {
    if (!userState.user.role_after_login) return // do, nothing if role is not set yet

    if (userState.user.is_admin) {
      if (location.pathname.startsWith(CLIENT_GALLERY_PREFIX)) {
        navigate(getGalleryUrl(
          userState.user.role_after_login,
          orderState.imageType,
          orderState.orderId,
          orderState.imageSize,
          orderState.imageSort,
        ))
      }
    } else if (location.pathname.startsWith(ADMIN_GALLERY_PREFIX)) { // all other roles
      navigate(getGalleryUrl(
        userState.user.role_after_login,
        orderState.imageType,
        orderState.orderId,
        orderState.imageSize,
        orderState.imageSort,
      ))
    }
  }, [userState.user])

  return (
    <>
      <div
        onScroll={handleLayoutScroll}
        ref={galleryContentRef}
        className="gallery-content scrollbar-overflow scrollbar-overflow__light"
      >
        <div className="images--wrap">
          <Previews refreshGallery={refreshGallery} />
        </div>
      </div>

      {orderState.selectedImages.length > 0 && (
        <Actions refreshGallery={refreshGallery} resetInterval={resetInterval} />
      )}

      {layoutState.isInfosOpened && (
        <InfoBar />
      )}

      {layoutState.isOverlayOpened && (
        <Overlay refreshGallery={refreshGallery} />
      )}

      {Object.keys(orderState.downloadUrls).length > 0 && (
        <Download urls={orderState.downloadUrls} />
      )}

      {layoutState.isDialogModalOpened && (
        <Dialogs refreshGallery={refreshGallery} />
      )}

      {layoutState.isDeleteErrorsModalOpened && (
        <DeleteErrorsDialog refreshGallery={refreshGallery} />
      )}

      {layoutState.isExplodeOrderModalOpened && (
        <ExplodeOrderDialog refreshGallery={refreshGallery} />
      )}

      {layoutState.isUpdatePricesModalOpened && (
        <UpdatePricesDialog refreshGallery={refreshGallery} />
      )}

      <Loader />
    </>
  )
}

export default Gallery
