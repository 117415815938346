import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../helpers/Translation'

import Typography from '../Typography'
import Modal from '../Modal'

import './index.scss'

const getClassName = (isCentered) => `${isCentered ? 'modal--center' : ''}`

const Dialog = ({
  id,
  title,
  content,
  cancelLabel,
  yesLabel,
  size,
  isShown,
  isCentered,
  hasOnlyYes,
  hasCloseIcon,
  onClickCloseIcon,
  onClickOutside,
  onClickYes,
  onClickCancel,
}) => (
  <Modal
    id={id}
    size={size}
    onClickOutside={onClickOutside}
    isShown={isShown}
    hasCloseIcon={hasCloseIcon}
    onClickCloseIcon={onClickCloseIcon}
  >
    <div className={getClassName(isCentered)}>
      <div>
        <Typography
          variant="h6"
          font="semibold"
          label={title}
        />
      </div>

      <div className="m-top-8 m-bottom-32">
        {content}
      </div>

      <div className="m-top-auto flex justify-center column-gap-16">
        {
          (!hasOnlyYes)
            ? (
              <div className="column-half">
                <button
                  type="button"
                  label={cancelLabel}
                  onClick={onClickCancel}
                  className="dialog-button cancel"
                >
                  {cancelLabel}
                </button>
              </div>
            )
            : null
        }

        <div className="column-half">
          <button
            type="button"
            label={yesLabel}
            onClick={onClickYes}
            className="dialog-button yes"
          >
            {yesLabel}
          </button>
        </div>
      </div>
    </div>
  </Modal>
)

Dialog.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
  cancelLabel: PropTypes.string,
  yesLabel: PropTypes.string,
  size: PropTypes.oneOf(['', 'small', 'medium', 'large', 'xl']),
  isShown: PropTypes.bool,
  isCentered: PropTypes.bool,
  hasOnlyYes: PropTypes.bool,
  hasCloseIcon: PropTypes.bool,
  onClickOutside: PropTypes.func,
  onClickCloseIcon: PropTypes.func,
  onClickYes: PropTypes.func,
  onClickCancel: PropTypes.func,
}

Dialog.defaultProps = {
  id: 'modal',
  title: 'Dialog',
  content: (<Typography label={Translation.my_doopic} variant="xs" />),
  cancelLabel: 'Cancel',
  yesLabel: 'Yes',
  size: '',
  isShown: false,
  isCentered: true,
  hasOnlyYes: false,
  hasCloseIcon: false,
  onClickOutside: () => { },
  onClickCloseIcon: () => { },
  onClickYes: () => { },
  onClickCancel: () => { },
}

export default Dialog
