import React from 'react'
import { useStoreState } from 'easy-peasy'

import { Translation } from '../../helpers/Translation'
import { redirectToMyDoopic } from '../../helpers/Utils'

import Typography from '../../components/Typography'

import { ReactComponent as LogoSvg } from '../../svg/logo.svg'

import './index.scss'
import { PX_TO_REM } from '../../helpers/Constants'

const Home = () => {
  const layoutState = useStoreState((state) => ({
    isMobile: state.layout.isMobile,
  }))

  const handleClick = () => {
    redirectToMyDoopic()
  }

  return (
    <div className="error-page">
      <div className="error--wrap">
        <div className="header-logo--wrap">
          <div className="header-logo">
            <div
              onClick={handleClick}
              id="logo-link"
            >
              <LogoSvg className="header-logo--icon" />
            </div>
          </div>
        </div>

        <div className="screen-center">
          <div className="width-100vw">
            <div className={`${layoutState.isMobile ? 'm-top-20' : 'm-top-24'} error-title`}>
              <Typography
                variant="h2"
                font="medium"
                label={Translation.no_order_selected}
                fontSize={layoutState.isMobile ? PX_TO_REM['35'] : null}
                lineHeight={layoutState.isMobile ? PX_TO_REM['44'] : PX_TO_REM['72']}
              />
            </div>

            <div className={`${layoutState.isMobile ? 'm-top-18' : 'm-top-24'}`}>
              <button
                type="button"
                label={Translation.take_me_home}
                className="button fit-content"
                onClick={handleClick}
              >
                {Translation.take_me_home}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Home.propTypes = {}

Home.defaultProps = {}

export default Home
