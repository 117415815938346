import React, { useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import RadioGroup from '../../../../components/RadioGroup'

import { ReactComponent as OverlayBackgroundTransparentSvg } from '../../../../svg/overlay_background_transparent.svg'
import { ReactComponent as OverlayBackgroundAddSvg } from '../../../../svg/overlay_background_add.svg'
import { ReactComponent as OverlayBackgroundRemoveSvg } from '../../../../svg/overlay_background_remove.svg'

import './index.scss'

const BG_COLORS = [
  '#8ACB88',
  '#A2BEFA',
  '#E1E1E1',
  '#FF0F0F',
  '#75B9BE',
  '#EAD870',
  '#A8A8A8',
]

const Background = () => {
  const orderState = useStoreState((state) => ({
    selectedBgColor: state.order.selectedBgColor,
  }))

  const orderActions = useStoreActions((actions) => ({
    setSelectedBgColor: actions.order.setSelectedBgColor,
  }))

  const [selectedBackgroundType, setSelectedBackgroundType] = useState('both')
  const values = {
    both: 'Both',
    background: 'Image Background',
    border: 'Border',
  }

  const onChange = (e) => {
    setSelectedBackgroundType(e.target.value)
  }

  const onRemove = () => { }

  const onAdd = () => { }

  const onSelectBgColor = (color) => {
    orderActions.setSelectedBgColor(color)
  }

  return (
    <div className="background-block-container">
      <div className="background-left">
        <RadioGroup
          onChange={onChange}
          value={selectedBackgroundType}
          values={values}
        />
      </div>
      <div className="background-right">
        <div
          className={`color${orderState.selectedBgColor === null ? ' selected' : ''}`}
          onClick={() => onSelectBgColor(null)}
        >
          <OverlayBackgroundTransparentSvg />
        </div>
        {BG_COLORS.map((color) => (
          <div
            key={color}
            className={`color${color === orderState.selectedBgColor ? ' selected' : ''}`}
            style={{ backgroundColor: color }}
            onClick={() => onSelectBgColor(color)}
          >
            <OverlayBackgroundRemoveSvg className="remove" onClick={onRemove} />
          </div>
        ))}
        {Array.from({ length: 9 - BG_COLORS.length }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="color empty" onClick={onAdd}>
            <OverlayBackgroundAddSvg />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Background
