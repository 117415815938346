import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import OrderId from './OrderId'
import ImageTypes from './ImageTypes'
import Stats from './Stats'
import Avatars from './Avatars'
import Controls from './Controls'
import Filters from './Filters'
import ImageSizeRange from './ImageSizeRange'

import { ReactComponent as InfoIconSvg } from '../../svg/info_icon.svg'
import { ReactComponent as RedoIconSvg } from '../../svg/redo_icon.svg'
import { ReactComponent as BackIconSvg } from '../../svg/back_icon.svg'

import './index.scss'

const Header = () => {
  const layoutState = useStoreState((state) => ({
    isInfosOpened: state.layout.isInfosOpened,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateInfosOpened: actions.layout.updateInfosOpened,
  }))

  const handleInfoClick = () => {
    layoutActions.updateInfosOpened(!layoutState.isInfosOpened)
  }

  return (
    <div className="header">
      <div className="header--middle__wrap--left">
        <div className="header--wraps header--back__wrap">
          <BackIconSvg />
        </div>

        <div
          className={`header--wraps header--image-info__wrap ${layoutState.isInfosOpened
            ? 'header--image-info__wrap--active' : ''}`}
          onClick={handleInfoClick}
        >
          <InfoIconSvg />
        </div>

        <div className="header--wraps header--send-redo__wrap">
          <RedoIconSvg />
        </div>

        <OrderId />

        <ImageTypes />

        <Stats />
      </div>

      <div className="header--middle__wrap--middle" />

      <div className="header--middle__wrap--right">
        <ImageSizeRange />

        <Avatars />

        <Controls />

        <Filters />
      </div>
    </div>
  )
}

export default Header
