import React from 'react'

import { Translation } from '../../../helpers/Translation'

import './index.scss'

const Loader = () => {
  // todo Yigit - should come from Store
  const isPreviewsLoading = false

  return (
    <div id="dialog--update" className="dialog--update" style={isPreviewsLoading ? {} : { display: 'none' }}>
      <div className="dialog-loader--wrap">
        {Translation.updating}
        ...
      </div>
    </div>
  )
}

export default Loader
